import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { NdviRepository } from "../../repository/ndvi.repository";
const calculateDaysDifference = (dateString) => {
  const today = new Date();
  const inputDate = new Date(dateString);
  const timeDifference = today - inputDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};
const INTERVAL = [5, 10, 15];
const SATELLITE = ["AWiFS and Sentinel", "Modis"];
const useNdviDashboard = () => {
  const alert = useAlert();
  const [ndviData, setNdviData] = useState([
    // {
    //   farmer_name: "Farmer Name",
    //   farmer_phone_number: "9198494118",
    //   farmer_village: "Village",
    //   farmer_district_en: "District",
    //   farmer_district_hi: "District",
    //   farmer_state: "State",
    //   plot_no: "Plot No",
    //   crop_name: "Crop Name",
    //   crop_variety: "Variety",
    //   crop_duration: "crop duration", // Add logic if needed
    //   current_crop_stage: "Crop Stage",
    //   sowing_season: "crop season", // Add logic if needed
    //   coordinates: {
    //     latitude: "latitude",
    //     longitude: "longitude",
    //   },
    //   ndvi_current: 0.5,
    //   ndvi_max: 1,
    //   last_updated: "2021-09-01",
    //   satellite: "",
    //   interval: "",
    //   monitoring_status: "Monitoring Status",
    // },
    // {
    //   farmer_name: "Farmer Name",
    //   farmer_phone_number: "9198494118",
    //   farmer_village: "Village",
    //   farmer_district_en: "District",
    //   farmer_district_hi: "District",
    //   farmer_state: "State",
    //   plot_no: "Plot No",
    //   crop_name: "Crop Name",
    //   crop_variety: "Variety",
    //   crop_duration: "crop duration", // Add logic if needed
    //   current_crop_stage: "Crop Stage",
    //   sowing_season: "crop season", // Add logic if needed
    //   coordinates: {
    //     latitude: "latitude",
    //     longitude: "longitude",
    //   },
    //   ndvi_current: 0.5,
    //   ndvi_max: 1,
    //   last_updated: "2021-09-01",
    //   satellite: "Satellite",
    //   interval: "Interval",
    //   monitoring_status: "Monitoring Status",
    // },
  ]);
  const [isLoading, setIsloading] = useState(true);
  const [ndvi, setNdvi] = useState(0);
  const [interval, setInterval] = useState(INTERVAL[1]);
  const [satellite, setSatellite] = useState(SATELLITE[0]);
  const [lastUpdated, setLastUpdated] = useState(null);

  const [editableRowId, setEditableRowId] = useState(null);
  const [editedRowData, setEditedRowData] = useState({});
  const handleNdvichange = (e) => {
    setNdvi(e.target.value);
  };
  const handleLastUpdatedChange = (e) => {
    setLastUpdated(e.target.value);
  };
  const handleSatelliteChange = (e) => {
    setSatellite(e.target.value);
  };
  const handleIntervalChange = (e) => {
    setInterval(e.target.value);
  };
  const fetchNdviRecords = async () => {
    // Fetch NDVI records from the server
    try {
      setIsloading(true);
      const ndviData = await NdviRepository.getNdviData(1);
      setNdviData(ndviData.data);
    } catch (error) {
      alert.error(error.message);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetchNdviRecords();
  }, []);

  const handleEditRow = (index) => {
    setEditableRowId(index);
    setEditedRowData({ ...ndviData[index] }); // Store the current row data in state
  };

  const handleSubmitRow = async (index) => {
    if (
      editedRowData.ndvi === undefined ||
      editedRowData.ndvi === null ||
      editedRowData.ndvi === ""
    ) {
      alert.error("Please enter a valid NDVI value");
      return;
    }
    if (
      editedRowData.lastUpdated === undefined ||
      editedRowData.lastUpdated === null ||
      editedRowData.lastUpdated === ""
    ) {
      alert.error("Please select a valid last updated date");
      return;
    }
    const obj = {
      ndviValue: editedRowData.ndvi,
      last_updated: editedRowData.lastUpdated,
      satellite: editedRowData.satellite,
    };
    try {
      const response = await NdviRepository.updateNdviData(
        editedRowData._id,
        obj
      );
      alert.success(response.message);
      fetchNdviRecords();
    } catch (error) {
      alert.error(error.message);
    }
    setEditableRowId(null);
  };

  const handleChange = (index, field, value) => {
    if (editableRowId === index) {
      setEditedRowData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  return {
    ndvi,
    isLoading,
    ndviData,
    handleNdvichange,
    lastUpdated,
    handleLastUpdatedChange,
    interval,
    handleIntervalChange,
    satellite,
    handleSatelliteChange,
    SATELLITE,
    INTERVAL,
    editableRowId,
    editedRowData,
    handleEditRow,
    handleSubmitRow,
    handleChange,
  };
};

export default useNdviDashboard;
