import { useParams } from "react-router-dom";
import Component from "../../components";
import useCropEdit from "./useCropEdit";
import "./cropsEdit.css";

const CropScreen = () => {
  const { id } = useParams();
  const {
    crop,
    cropCategories,
    handleNewCropCategory,
    getNewCropsData,
    handleFileChange,
    updateCrops,
    addDuration,
    addNewKeyValuePair,
    handleDurationChange,
    handleKeyValueChange,
    durations,
    isLoading,
    ndviDialogOpen,
    handleNdviChange,
    handleAlphaChange,
    alphaValues,
    ndviValues,
    toggleNdviDialog,
    seasonModal,
    toggleSeasonModal,
    states,
    months,
    seasons,
    handleSeasonChange,
    handleAddSeason,
    cropSeasons,
    handleDeleteSeason,
  } = useCropEdit(id);

  if (isLoading) return <h1>Loading...</h1>;

  return (
    <div
      className="container"
      style={{ maxHeight: "500px", overflowY: "auto" }}
    >
      <Component.Row justifyContent="space-between" alignItems="center">
        <h2 className="">Update Crop</h2>
        <select name="cropCategory" onChange={handleNewCropCategory}>
          <option value=""> Select Category </option>
          {cropCategories?.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
      </Component.Row>

      <Component.Row justifyContent="space-between" alignItems="center">
        <Component.Button
          title="Add Crop Season"
          onClick={toggleSeasonModal}
          style={{ width: "100px" }}
        />
        <Component.DailogBox
          isOpen={seasonModal}
          toggleModel={toggleSeasonModal}
          onClose={toggleSeasonModal}
        >
          <Component.Row justifyContent="space-between" alignItems="center">
            <h2 className="">Add Crop Season</h2>
          </Component.Row>
          <Component.Spacer position="top" size={20} />
          <Component.Row
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "15px", width: "100%" }}
          >
            <select
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                width: "45%",
                fontSize: "14px",
              }}
              name="state"
              onChange={handleSeasonChange}
            >
              <option value=""> Select State </option>
              {states?.map((state) => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            </select>
          </Component.Row>
          <Component.Row
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "15px", width: "100%" }}
          >
            <select
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                width: "45%",
                fontSize: "14px",
              }}
              name="month"
              onChange={handleSeasonChange}
            >
              <option value=""> Select Month </option>
              {months?.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </Component.Row>
          <Component.Row
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: "15px", width: "100%" }}
          >
            <select
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                width: "45%",
                fontSize: "14px",
              }}
              name="season"
              onChange={handleSeasonChange}
            >
              <option value=""> Select Season </option>
              {seasons?.map((season) => (
                <option key={season.value} value={season.value}>
                  {season.label}
                </option>
              ))}
            </select>
          </Component.Row>
          <Component.Button
            title="Add Season"
            onClick={handleAddSeason}
            style={{ width: "100px" }}
          />

          <Component.Spacer position="top" size={20} />
          <Component.Row>
            {Object.entries(cropSeasons).map(([state, months]) => (
              <Component.Row key={state}>
                <div>
                  <h3>{state}</h3>
                  <ul>
                    {/* Iterate over each month and season */}
                    {Object.entries(months).map(([month, season]) => (
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        key={month}
                      >
                        <li>
                          Month: {month} - Season: {season}
                        </li>
                        <Component.Button
                          title="Delete"
                          onClick={() => handleDeleteSeason(state, month)}
                          style={{ width: "100px" }}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              </Component.Row>
            ))}
          </Component.Row>
        </Component.DailogBox>
      </Component.Row>

      <Component.Column>
        <h3 className="cropModalHeaders">Name</h3>
        <Component.AuthInputField
          name="name"
          value={crop.name}
          onChange={getNewCropsData}
          placeholder={"Crop Name"}
        />

        <h3 className="cropModalHeaders">Name in Hindi</h3>
        <Component.AuthInputField
          name="name_hi"
          value={crop.name_hi}
          onChange={getNewCropsData}
          placeholder={"Crop Name"}
        />
        {/* <h3 className="cropModalHeaders">Crop Variety</h3>
        <Component.AuthInputField
          name="variety"
          value={crop.variety}
          onChange={getNewCropsData}
          placeholder={"Crop Variety"}
        /> */}
        {crop.image && (
          <img style={{ width: "100px" }} src={crop.image} alt="crop" />
        )}
        <h3 className="cropModalHeaders">Image</h3>
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/png, image/jpeg"
          placeholder="Any Image for Suggestions"
          name="image"
        />
      </Component.Column>
      <Component.Column>
        {durations?.map((duration, index) => (
          <div key={index}>
            <h3 className="cropModalHeaders">
              Duration:
              <Component.AuthInputField
                name="duration"
                value={duration?.duration}
                onChange={(e) =>
                  handleDurationChange(index, "duration", e.target.value)
                }
                placeholder={"Duration"}
              />
              <Component.Button
                onClick={() => toggleNdviDialog(duration._id)}
                title="Add NDVI Values"
              />
            </h3>

            {ndviDialogOpen[duration._id] && (
              <Component.DailogBox
                toggleModel={() => toggleNdviDialog(duration._id)}
                isOpen={ndviDialogOpen[duration._id]}
              >
                <h3>Duration : {duration.duration}</h3>
                {duration?.cropCycle_json?.map((keyValue, keyIndex) => (
                  <Component.Row key={keyIndex} className="key-value-pair">
                    <Component.AuthInputField
                      name="key"
                      value={keyValue?.key || ""}
                      placeholder={"Key"}
                      disabled
                    />
                    <Component.AuthInputField
                      name="ndvi_value"
                      value={ndviValues[index]?.ndvi_json[keyValue.key] || ""}
                      onChange={(e) =>
                        handleNdviChange(index, keyValue.key, e.target.value)
                      }
                      placeholder={"Enter NDVI Value"}
                    />
                    <Component.AuthInputField
                      name="alpha_value"
                      value={alphaValues[index]?.alpha_json[keyValue.key] || ""}
                      onChange={(e) =>
                        handleAlphaChange(index, keyValue.key, e.target.value)
                      }
                      placeholder={"Enter Alpha Value"}
                    />
                  </Component.Row>
                ))}
                <Component.Button
                  title="Update"
                  onClick={() => toggleNdviDialog(duration._id)}
                />
              </Component.DailogBox>
            )}
            {duration?.cropCycle_json?.map((keyValue, keyIndex) => (
              <Component.Row key={keyIndex} className="key-value-pair">
                <Component.AuthInputField
                  name="key"
                  value={keyValue?.key || ""}
                  onChange={(e) =>
                    handleKeyValueChange(index, keyIndex, "key", e.target.value)
                  }
                  placeholder={"Enter Key"}
                />
                <Component.AuthInputField
                  name="value"
                  value={keyValue?.value || ""}
                  onChange={(e) =>
                    handleKeyValueChange(
                      index,
                      keyIndex,
                      "value",
                      e.target.value
                    )
                  }
                  placeholder={"Enter Value"}
                />
              </Component.Row>
            ))}
            <Component.Button
              title="Add Key-Value Pair"
              onClick={() => addNewKeyValuePair(index)}
            />
          </div>
        ))}
        <Component.Spacer position={"top"} size={30} />
        <Component.Button title="Add Duration" onClick={() => addDuration()} />
      </Component.Column>
      <Component.Spacer position={"top"} size={30} />
      <Component.Button
        title={"Update"} //{isUpdate ? "Update" : "Add"}
        onClick={updateCrops} //isUpdate ? updateCrops : createCrops}
      />
    </div>
  );
};

export default CropScreen;
