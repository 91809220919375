import { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { AgriStickRepository as AbriStickRepository } from "../../repository/agriStick.repository";
import { FileUploadRepository } from "../../repository/fileUpload.repository";
import { useNavigate } from "react-router-dom";
const useCropEdit = (id) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const fileUploadRepository = new FileUploadRepository();
  const [crop, setCrop] = useState({});
  const [durations, setdurations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
  const [newCropCategory, setNewCropCategory] = useState({});
  const [formData, setFormData] = useState(null);
  const [cropCategories, setCropCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("*");
  const [newCropName, setNewCropName] = useState({
    name: "",
    name_hi: "",
    category: "",
    variety: "",
  });
  const [duration, setDuration] = useState([
    {
      duration: "",
      cropCycle_json: [
        {
          key: "",
          value: "",
        },
      ],
    },
  ]);
  const [cropCycleModal, setCropCycleModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  // this is for ndvi values
  const [ndviDialogOpen, setNdviDialogOpen] = useState({});
  const [ndviValues, setNdviValues] = useState(
    durations.map((duration) => ({
      duration_ref: duration._id,
      ndvi_json: duration?.cropCycle_json?.reduce((acc, { key }) => {
        acc[key] = "";
        return acc;
      }, {}),
    }))
  );
  const [alphaValues, setAlphaValues] = useState(
    durations.map((duration) => ({
      duration_ref: duration._id,
      alpha_json: duration?.cropCycle_json?.reduce((acc, { key }) => {
        acc[key] = ""; // Initialize alpha values for each key
        return acc;
      }, {}),
    }))
  );

  // This is for crop season
  const [cropSeasons, setCropSeasons] = useState({});
  const [seasonModal, setSeasonModal] = useState(false);
  const states = [
    { value: "uttar_pradesh", label: "Uttar Pradesh" },
    { value: "madhya_pradesh", label: "Madhya Pradesh" },
  ];
  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const seasons = [
    { value: "kharif", label: "Kharif" },
    { value: "rabi", label: "Rabi" },
    { value: "zaid", label: "Zaid" },
  ];

  const [newSeason, setNewSeason] = useState({
    state: "",
    month: "",
    season: "",
  });

  const toggleSeasonModal = () => {
    setSeasonModal(!seasonModal);
  };

  const handleSeasonChange = (e) => {
    const { name, value } = e.target;
    setNewSeason((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSeason = async () => {
    try {
      if (newSeason.state && newSeason.month && newSeason.season) {
        setIsLoading(true);
        await AbriStickRepository.addCropSeason(id, newSeason);
        alert.success("Season added successfully!");
        getCropSeasons();
      } else {
        alert.error("Please fill out all the fields.");
      }
    } catch (err) {
      alert.error("Error adding season:", err);
      console.error("Error adding season:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSeason = async (state, month) => {
    try {
      setIsLoading(true);
      const payload = { state, month };
      console.log("i am here");
      await AbriStickRepository.deleteCropSeason(id, payload);
      alert.success("Season deleted successfully!");
      getCropSeasons();
    } catch (err) {
      alert.error("Error deleting season:", err);
      console.error("Error deleting season:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const getCropSeasons = async () => {
    try {
      const cropSeasons = await AbriStickRepository.getCropSeasons(id);
      setCropSeasons(cropSeasons.stateSeasons);
    } catch (err) {
      alert.error("Error fetching crop seasons:", err);
      console.error("Error fetching crop seasons:", err);
    }
  };

  const toggleNdviDialog = (durationId) => {
    //close dialog if durationId is not present
    if (!durationId) {
      //close dialog if durationId is not present
      return;
    }
    setNdviDialogOpen((prevState) => ({
      ...prevState,
      [durationId]: !prevState[durationId],
    }));
  };

  const handleNdviChange = (durationIndex, key, value) => {
    const updatedNdviValues = [...ndviValues];
    updatedNdviValues[durationIndex].ndvi_json[key] = value;
    setNdviValues(updatedNdviValues);
  };

  const handleAlphaChange = (alphaIndex, key, value) => {
    const updatedAlphaValues = [...alphaValues];
    updatedAlphaValues[alphaIndex].alpha_json[key] = value;
    setAlphaValues(updatedAlphaValues);
  };

  const resetForm = () => {
    setIsUpdate(false);
    setDuration([
      {
        duration: "",
        cropCycle_json: [
          {
            key: "",
            value: "",
          },
        ],
      },
    ]);
    setFormData(null);
  };

  const toggleModal = () => {
    setIsOpen((prev) => {
      if (prev) {
        setNewCropName("");
        setNewCropCategory("");
        setIsUpdate(false);
      }
      return !prev;
    });
  };

  const toggleAddCategoryModal = () => {
    setIsAddCategoryOpen((prev) => {
      if (prev) setNewCropCategory("");
      return !prev;
    });
  };

  const getNewCropsData = (event) => {
    setCrop((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const getCrops = async () => {
    try {
      setIsLoading(true);
      const response = await AbriStickRepository.getSingleCrop(id);
      setCrop(response);

      const durations =
        response?.durations?.map((item) => ({
          _id: item._id,
          duration: item.duration,
          cropCycle_json: JSON.parse(item.cropCycle_json),
        })) || [];

      const ndviValues = response?.ndviValues?.length
        ? response.ndviValues.map((item) => ({
            duration_ref: item.duration_ref,
            ndvi_json: JSON.parse(item.ndvi_json),
          }))
        : durations.map(({ _id, cropCycle_json }) => ({
            duration_ref: _id,
            ndvi_json: Object.fromEntries(
              cropCycle_json.map(({ key }) => [key, ""])
            ),
          }));

      const alphaValues = response?.alphaValues?.length
        ? response.alphaValues.map((item) => ({
            duration_ref: item.duration_ref,
            alpha_json: JSON.parse(item.alpha_json),
          }))
        : durations.map(({ _id, cropCycle_json }) => ({
            duration_ref: _id,
            alpha_json: Object.fromEntries(
              cropCycle_json.map(({ key }) => [key, ""])
            ),
          }));

      setdurations(durations);
      setNdviValues(ndviValues);
      setAlphaValues(alphaValues);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };

  const createCrops = async () => {
    try {
      if (
        newCropName?.name &&
        newCropName?.name_hi &&
        newCropCategory &&
        formData &&
        crops?.every((crops) => crops.name !== newCropName)
      ) {
        toggleModal();
        setIsLoading(true);
        const imgResponse = await fileUploadRepository.uploadImage(formData);
        const payload = {
          category: newCropCategory.cropCategory,
          name: newCropName?.name,
          name_hi: newCropName?.name_hi,
          image: imgResponse.imgurl,
          variety: newCropName?.variety,
        };

        await AbriStickRepository.createCrops(payload);
        alert.success("Crop Created SuccessFully");
        getCrops();

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const updateCrops = async () => {
    try {
      if (crop?.name && crop?.name_hi) {
        setIsLoading(true);
        const imgResponse = formData
          ? await fileUploadRepository.uploadImage(formData)
          : null;
        const filteredDuration = durations.filter((item) => {
          // Only include entries with non-empty duration and cropCycle_json
          return (
            item.duration.trim() !== "" ||
            item.cropCycle_json.some((kv) => kv.key.trim() && kv.value.trim())
          );
        });

        const newDuration = filteredDuration.map((item) => ({
          ...item,
          cropCycle_json: JSON.stringify(item.cropCycle_json),
        }));
        const newNdviValues = ndviValues.map((item) => ({
          ...item,
          ndvi_json: JSON.stringify(item.ndvi_json),
        }));

        const newAlphaValues = alphaValues.map((item) => ({
          ...item,
          alpha_json: JSON.stringify(item.alpha_json),
        }));
        const payload = {
          _id: crop?._id,
          category: newCropCategory.cropCategory,
          name: crop?.name,
          name_hi: crop?.name_hi,
          variety: crop?.variety,
          duration: newDuration,
          ndviValues: newNdviValues,
          alphaValues: newAlphaValues,
          image: imgResponse?.imgurl,
        };

        await AbriStickRepository.updateCrops(payload);
      }
      alert.success("Crop Updated SuccessFully");
      getCrops();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          const error = new Error("image size should be less than 1MB");
          error.statusCode = 400;
          throw error;
        }
        const data = new FormData();
        data.append("image", file);
        setFormData(data);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const handleNewCropCategory = (event) => {
    setNewCropCategory((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const createNewCategory = async () => {
    try {
      if (newCropCategory?.name && newCropCategory?.name_hi) {
        const payload = { ...newCropCategory };
        await AbriStickRepository.createCropCategory(payload);
        toggleAddCategoryModal();
        alert.success("successfully created new crop category");
      }
    } catch (err) {
      toggleAddCategoryModal();
      alert.error(err.message);
    }
  };

  const handleSelectedCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const getCropCategories = async () => {
    const data = await AbriStickRepository.getCropCategories();
    setCropCategories(data);
  };

  const toggleCropCycleModal = () => {
    setCropCycleModal((prev) => !prev);
  };

  const cropClicked = (crop) => {
    setIsUpdate(true);
    setNewCropName(crop);
    const formattedData =
      crop.durations.length !== 0
        ? crop.durations.map((item) => ({
            duration: item.duration,
            cropCycle_json: JSON.parse(item.cropCycle_json),
          }))
        : [
            {
              duration: "",
              cropCycle_json: [
                {
                  key: "",
                  value: "",
                },
              ],
            },
          ];

    setDuration(formattedData);
    // toggleModal();
    navigate(`/crops/${crop._id}`);
  };

  const handleDurationChange = (index, field, value) => {
    // Make a copy of the current durations array
    const updatedDuration = [...durations];
    // Update the specific field (either 'duration' or 'cropCycle_json') for the corresponding index
    updatedDuration[index][field] = value;
    // Update the state with the modified array
    setdurations(updatedDuration);
  };

  const handleKeyValueChange = (durationIndex, keyIndex, field, value) => {
    const updatedDurations = [...durations];
    updatedDurations[durationIndex].cropCycle_json[keyIndex][field] = value;
    setdurations(updatedDurations);
  };

  const addNewKeyValuePair = (durationIndex) => {
    const updatedDurations = [...duration];
    durations[durationIndex].cropCycle_json.push({ key: "", value: "" });
    setDuration(updatedDurations);
  };

  const addDuration = () => {
    setdurations([
      ...durations,
      { duration: "", cropCycle_json: [{ key: "", value: "" }] },
    ]);
  };

  useEffect(() => {
    getCropCategories();
  }, []);

  useEffect(() => {
    getCrops();
  }, [selectedCategory]);

  useEffect(() => {
    getCropSeasons();
  }, []);
  // Here i have to writenctinality for ndvi values which like for each keyvaluepaer in each durain there should be a simepl
  return {
    crop,
    durations,
    isLoading,
    isOpen,
    toggleModal,
    getNewCropsData,
    handleFileChange,
    newCropName,
    createCrops,
    isAddCategoryOpen,
    toggleAddCategoryModal,
    handleNewCropCategory,
    createNewCategory: createNewCategory,
    cropCategories,
    handleSelectedCategory: handleSelectedCategory,
    cropClicked,
    cropCycleModal,
    toggleCropCycleModal,
    handleKeyValueChange,
    isUpdate,
    updateCrops,
    handleDurationChange,
    addNewKeyValuePair,
    duration,
    addDuration,
    ndviDialogOpen,
    handleNdviChange,
    handleAlphaChange,
    ndviValues,
    alphaValues,
    toggleNdviDialog,
    // this is for adding season
    seasonModal,
    toggleSeasonModal,
    states,
    seasons,
    months,
    handleSeasonChange,
    handleAddSeason,
    cropSeasons,
    handleDeleteSeason,
  };
};
export default useCropEdit;
