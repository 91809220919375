import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class AgriStickRepository {
  static appUrl = new AppUrl();
  static apiService = new ApiService();
  static async generateIds(count) {
    try {
      const url = `${this.appUrl.agriStickEndPoint}/stick-ids?count=${count}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  static async getRecords(pageNo) {
    try {
      const url = `${this.appUrl.agriStickEndPoint}/records/${pageNo}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  static async updateStatus(id, status) {
    try {
      const url = `${this.appUrl.agriStickEndPoint}/updateStatus/${id}`;
      return await this.apiService.getPutApiResponse(url, { status });
    } catch (error) {
      throw error;
    }
  }
  static async getCrops(category) {
    let url;
    if (category === "*") url = `${this.appUrl.agriStickEndPoint}/crops`;
    else url = `${this.appUrl.agriStickEndPoint}/crops?category=${category}`;
    return await this.apiService.getGetApiResponse(url);
  }
  static async getSingleCrop(id) {
    const url = `${this.appUrl.agriStickEndPoint}/crops/${id}`;
    return await this.apiService.getGetApiResponse(url);
  }
  static async createCrops(payload) {
    const url = `${this.appUrl.agriStickEndPoint}/crops`;
    return await this.apiService.getPostApiResponse(url, payload);
  }
  static async updateCrops(payload) {
    const url = `${this.appUrl.agriStickEndPoint}/crops/${payload._id}`;
    return await this.apiService.getPutApiResponse(url, payload);
  }
  static async createCropCategory(payload) {
    const url = `${this.appUrl.agriStickEndPoint}/cropCategory`;
    return await this.apiService.getPostApiResponse(url, payload);
  }
  static async getCropCategories() {
    const url = `${this.appUrl.agriStickEndPoint}/cropCategory`;
    return await this.apiService.getGetApiResponse(url);
  }
  static async getUserInterest(page) {
    const url = `${this.appUrl.agriStickEndPoint}/interest?pageNo=${page}`;
    return this.apiService.getGetApiResponse(url);
  }
  // crop season
  static async addCropSeason(cropId, payload) {
    const url = `${this.appUrl.agriStickEndPoint}/addCropSeason/${cropId}`;
    return this.apiService.getPostApiResponse(url, payload);
  }
  static async deleteCropSeason(cropId, payload) {
    const url = `${this.appUrl.agriStickEndPoint}/cropSeason/${cropId}`;
    return this.apiService.getDeleteApiResponse(url, payload);
  }
  static async getCropSeasons(id) {
    const url = `${this.appUrl.agriStickEndPoint}/cropSeasons/${id}`;
    return this.apiService.getGetApiResponse(url);
  }
}
