import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class SoilTestingRepository {
  constructor() {
    this.appUrl = new AppUrl();
    this.apiService = new ApiService();
  }
  async getSoilTestingRequests() {
    try {
      const url = `${this.appUrl.soilTestEndPoint}/request`;
      return await this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async getUserDetails(phoneNumber) {
    try {
      const url = `${this.appUrl.soilTestEndPoint}/userDetails/${phoneNumber}`;
      return await this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async addRequest(payload) {
    try {
      const url = `${this.appUrl.soilTestEndPoint}/request`;
      return await this.apiService.getPostApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }
}
