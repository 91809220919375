import React, { useState } from "react";
import "./styles.css"; // Ensure you have this stylesheet
// import Modal from "react-modal";
import Logo from "./logoagrichikitsa.png";
import { usePDF } from "react-to-pdf";
import { useSoilReportManager } from "./soilReportManager";

const SoilTestCard = ({ soilData = {}, isOpen, handleModal }) => {
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [compositionNo, setCompositionNo] = useState(1);
  const handleChange = (event) => {
    setCompositionNo(Number(event.target.value));
  };
  const { reportData } = useSoilReportManager(compositionNo);

  const {
    OrganicCarbon = { value: "", condition: "High" },
    Conductivity = { value: "", condition: "" },
    Nitrogen = { value: "", condition: "" },
    Phosphorus = { value: "", condition: "" },
    Potassium = { value: "", condition: "" },
    Sulfur = { value: "", condition: "" },
    Zinc = { value: "", condition: "" },
    Boron = { value: "", condition: "" },
    Iron = { value: "", condition: "" },
    Magnesium = { value: "", condition: "" },
    Copper = { value: "", condition: "" },
    SulfurAvailability = "",
    BoronAvailability = "",
    MagnesiumAvailability = "",
    pH = { value: "", condition: "" },
    Urea = 0,
    DAP = 0,
    MOP = 0,
    ZincSulfate = 0,
    Gypsum = 0,
    Borax = 0,
    FerrousSulfate = 0,
    ManganeseSulfate = 0,
    CopperSulfate = 0,
    Lime = 0,
    CalciumSulfate = 0,
    schedule = {
      Stage1: { Urea: "", Week: "" },
      Stage2: { Urea: "", Week: "" },
      Stage3: { Urea: "", Week: "" },
    },
  } = reportData;

  const getHighlightClass = (condition) => {
    switch (condition) {
      case "High":
        return "highlight-green";
      case "Very High":
        return "highlight-dark-green";
      case "Sufficient":
        return "highlight-green";
      case "Deficient":
        return "highlight-red";
      case "Low":
        return "highlight-red";
      case "Medium":
        return "highlight-yellow";
      case "Very Low Salinity":
        return "highlight-red";
      case "Medium Salinity":
        return "highlight-yellow";
      case "Very High Salinity":
        return "highlight-green";
      default:
        return "highlight-default";
    }
  };

  const getPHHighlightClass = (condition) => {
    switch (condition) {
      case "Very Strongly Acidic":
        return "highlight-red";
      case "Strongly Acidic":
        return "highlight-orange";
      case "Moderately Acidic":
        return "highlight-yellow";
      case "Slightly Acidic":
        return "highlight-light-yellow";
      case "Neutral":
        return "highlight-green";
      case "Moderately Alkaline":
        return "highlight-light-blue";
      case "Strongly Alkaline":
        return "highlight-blue";
      default:
        return "highlight-default";
    }
  };

  const translateConditionToHindi = (condition) => {
    switch (condition) {
      case "High":
        return "उच्च";
      case "Very High":
        return "अत्यधिक उच्च";
      case "Sufficient":
        return "पर्याप्त";
      case "Deficient":
        return "अल्प";
      case "Low":
        return "निम्न";
      case "Medium":
        return "मध्यम";
      case "Very Low Salinity":
        return "बहुत कम खारापन";
      case "Medium Salinity":
        return "मध्यम खारापन";
      case "Very High Salinity":
        return "बहुत उच्च खारापन";
      case "Very Strongly Acidic":
        return "बहुत तीव्र अम्लीय";
      case "Strongly Acidic":
        return "तीव्र अम्लीय";
      case "Moderately Acidic":
        return "मध्यम अम्लीय";
      case "Slightly Acidic":
        return "हल्का अम्लीय";
      case "Neutral":
        return "तटस्थ";
      case "Moderately Alkaline":
        return "मध्यम क्षारीय";
      case "Strongly Alkaline":
        return "तीव्र क्षारीय";
      default:
        return "अनिश्चित";
    }
  };

  const orchards = [
    "Papaya",
    "Banana",
    "Orange",
    "Mango",
    "Sweetlime",
    "Gooseberry",
  ];
  return (
    <>
      <div ref={targetRef} className="card">
        <div className="header">
          <div className="logo">
            <img src={Logo} alt="Logo" />
            <button onClick={toPDF} className="modal_download_button">
              Download Pdf
            </button>
          </div>
          <div>
            <div className="title">
              <h1>फसलों की सुरक्षा</h1>
              <h2>एग्रीचिकित्सा</h2>
              <p>
                पता: एग्री-क्लिनिक, किसानगंगा, बिर्कुनिया, सिंगरौली, मध्य प्रदेश
              </p>
            </div>
          </div>
        </div>
        <div className="content">
          {/* <div>
            <p>Testing Date : 12/06/2024 Expiry Date : 12/06/2025</p>
          </div> */}
          <table>
            <tr>
              <td>नमूना पहचान क्र.:</td>
              <td colSpan="2">{reportData?.request?.testId}</td>
              <td>नाम:</td>
              <td colSpan="2">{reportData?.request?.name}</td>
              <td>ग्राम:</td>
              <td colSpan="2">{reportData?.request?.village}</td>
            </tr>
            <tr>
              <td>खेत का क्षेत्रफल:</td>
              <td>{reportData?.request?.fieldSize}</td>
              <td className="highlight-yellow" colSpan="2">
                मिट्टी जांच का परिणाम
              </td>
              <td>खसरा नंबर</td>
              <td>{reportData?.request?.fieldKharaNumber}</td>
              <td>खेत का निर्देशांक:</td>
              <td colSpan="2">{reportData?.request?.fieldCoordinates}</td>
            </tr>
            <tr>
              <td>जैविक कार्बन:</td>
              <td
                className={
                  !isNaN(parseFloat(OrganicCarbon?.value))
                    ? getHighlightClass(OrganicCarbon.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(OrganicCarbon?.value))
                  ? parseFloat(OrganicCarbon.value).toFixed(2) + "%"
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(OrganicCarbon?.value))
                    ? getHighlightClass(OrganicCarbon.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(OrganicCarbon?.value))
                  ? translateConditionToHindi(OrganicCarbon.condition)
                  : ""}
              </td>
              <td>विद्युत चालकता:</td>
              <td>
                {!isNaN(parseFloat(Conductivity?.value))
                  ? parseFloat(Conductivity.value) + " मिली साइमन/सेमी"
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Conductivity?.value))
                    ? getHighlightClass(Conductivity.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Conductivity?.value))
                  ? translateConditionToHindi(Conductivity.condition)
                  : ""}
              </td>
              <td>पी. एच.</td>
              <td
                className={
                  !isNaN(parseFloat(pH?.value))
                    ? getPHHighlightClass(pH.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(pH?.value)) ? parseFloat(pH.value) : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(pH?.value))
                    ? getPHHighlightClass(pH.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(pH?.value))
                  ? translateConditionToHindi(pH.condition)
                  : ""}
              </td>
            </tr>
            <tr>
              <td>उपलब्ध नत्रजन:</td>
              <td
                className={
                  !isNaN(parseFloat(Nitrogen?.value))
                    ? getHighlightClass(Nitrogen.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Nitrogen?.value))
                  ? parseFloat(Nitrogen.value).toFixed(2) + " किग्रा/हेक्ट"
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Nitrogen?.value))
                    ? getHighlightClass(Nitrogen.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Nitrogen?.value))
                  ? translateConditionToHindi(Nitrogen.condition)
                  : ""}
              </td>
              <td>उपलब्ध फास्फोरस:</td>
              <td
                className={
                  !isNaN(parseFloat(Phosphorus?.value))
                    ? getHighlightClass(Phosphorus.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Phosphorus?.value))
                  ? parseFloat(Phosphorus.value).toFixed(2) + " किग्रा/हेक्ट."
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Phosphorus?.value))
                    ? getHighlightClass(Phosphorus.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Phosphorus?.value))
                  ? translateConditionToHindi(Phosphorus.condition)
                  : ""}
              </td>
              <td>उपलब्ध पोटाश:</td>
              <td
                className={
                  !isNaN(parseFloat(Potassium?.value))
                    ? getHighlightClass(Potassium.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Potassium?.value))
                  ? parseFloat(Potassium.value).toFixed(2) + " किग्रा/हेक्ट."
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Potassium?.value))
                    ? getHighlightClass(Potassium.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Potassium?.value))
                  ? translateConditionToHindi(Potassium.condition)
                  : ""}
              </td>
            </tr>
            <tr>
              <td>उपलब्ध सल्फर:</td>
              <td
                className={
                  !isNaN(parseFloat(Sulfur?.value))
                    ? getHighlightClass(Sulfur.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Sulfur?.value))
                  ? parseFloat(Sulfur.value).toFixed(2) + " मि.ग्रा./कि.ग्रा"
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Sulfur?.value))
                    ? getHighlightClass(Sulfur.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Sulfur?.value))
                  ? translateConditionToHindi(Sulfur.condition)
                  : ""}
              </td>
              <td>उपलब्ध तांबा:</td>
              <td
                className={
                  !isNaN(parseFloat(Copper?.value))
                    ? getHighlightClass(Copper.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Copper?.value))
                  ? parseFloat(Copper.value).toFixed(2) + " मि.ग्रा./कि.ग्रा."
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Copper?.value))
                    ? getHighlightClass(Copper.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Copper?.value))
                  ? translateConditionToHindi(Copper.condition)
                  : ""}
              </td>
              <td>उपलब्ध जस्ता:</td>
              <td
                className={
                  !isNaN(parseFloat(Zinc?.value))
                    ? getHighlightClass(Zinc.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Zinc?.value))
                  ? parseFloat(Zinc.value).toFixed(2) + " मि.ग्रा./कि.ग्रा."
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Zinc?.value))
                    ? getHighlightClass(Zinc.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Zinc?.value))
                  ? translateConditionToHindi(Zinc.condition)
                  : ""}
              </td>
            </tr>
            <tr>
              <td>उपलब्ध मैग्नीशियम:</td>
              <td
                className={
                  !isNaN(parseFloat(Magnesium?.value))
                    ? getHighlightClass(Magnesium.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Magnesium?.value))
                  ? parseFloat(Magnesium.value).toFixed(2) +
                    " मि.ग्रा./कि.ग्रा."
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Magnesium?.value))
                    ? getHighlightClass(Magnesium.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Magnesium?.value))
                  ? translateConditionToHindi(Magnesium.condition)
                  : ""}
              </td>
              <td>उपलब्ध लोहा:</td>
              <td
                className={
                  !isNaN(parseFloat(Iron?.value))
                    ? getHighlightClass(Iron.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Iron?.value))
                  ? parseFloat(Iron.value).toFixed(2) + " मि.ग्रा./कि.ग्रा."
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Iron?.value))
                    ? getHighlightClass(Iron.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Iron?.value))
                  ? translateConditionToHindi(Iron.condition)
                  : ""}
              </td>
              <td>उपलब्ध बोरान:</td>
              <td
                className={
                  !isNaN(parseFloat(Boron?.value))
                    ? getHighlightClass(Boron.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Boron?.value))
                  ? parseFloat(Boron.value).toFixed(2) + " किग्रा/हेक्ट."
                  : ""}
              </td>
              <td
                className={
                  !isNaN(parseFloat(Boron?.value))
                    ? getHighlightClass(Boron.condition)
                    : ""
                }
              >
                {!isNaN(parseFloat(Boron?.value))
                  ? translateConditionToHindi(Boron.condition)
                  : ""}
              </td>
            </tr>
          </table>
          <div style={{ display: "flex", justifyContent: "" }}>
            <h3>संयोजन चुने</h3>
            <select
              style={{ marginLeft: "10px" }}
              value={compositionNo}
              onChange={handleChange}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </div>
          <table>
            <thead>
              <tr>
                <th>क्रम संख्या</th>
                <th>उर्वरक के घटक</th>
                <th>सुझाई गई मात्रा</th>
                <th colSpan="3"> {reportData?.request?.cropName}</th>
              </tr>
              <tr>
                <th colSpan="3"></th>
                <th>
                  {orchards.includes(reportData?.request?.cropName)
                    ? "प्रति वर्ष में"
                    : "  बुआई/रोपाई के समय"}
                </th>
                <th>
                  {!orchards.includes(reportData?.request?.cropName) &&
                    `${schedule?.Stage2?.Week ?? ""} सप्ताह बाद प्रयोग करें`}
                </th>
                <th>
                  {!orchards.includes(reportData?.request?.cropName) &&
                    `${schedule?.Stage3?.Week ?? ""} सप्ताह बाद प्रयोग करें`}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>1</td>
                <td>Urea / Neam Coated Urea</td>
                <td>
                  {Math.max(Urea, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>
                  {schedule?.Stage1 ? (
                    <>
                      {!isNaN(Math.round(schedule?.Stage1.Urea))
                        ? Math.round(schedule?.Stage1.Urea)
                        : ""}
                    </>
                  ) : (
                    <>{!isNaN(Math.round(Urea)) ? Math.round(Urea) : ""}</>
                  )}
                </td>
                <td>
                  {!isNaN(Math.round(schedule?.Stage2?.Urea))
                    ? Math.round(schedule?.Stage2?.Urea)
                    : ""}
                </td>
                <td>
                  {!isNaN(Math.round(schedule?.Stage3?.Urea))
                    ? Math.round(schedule?.Stage3?.Urea)
                    : ""}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>DAP</td>
                <td>
                  {Math.max(DAP, 0)}
                  {orchards.includes(reportData?.request?.cropName)
                    ? " को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(DAP, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>MOP</td>
                <td>
                  {Math.max(MOP, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(MOP, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td>ZnSO4.7H2O</td>
                <td>
                  {Math.max(ZincSulfate, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(ZincSulfate, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td>Gypsum</td>
                <td>
                  {Math.max(Gypsum, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(Gypsum, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>6</td>
                <td>Borax</td>
                <td>
                  {Math.max(Borax, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(Borax, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>7</td>
                <td>FeSO4.7H2O</td>
                <td>
                  {Math.max(FerrousSulfate, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(FerrousSulfate, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>8</td>
                <td>MnSO4.3H2O</td>
                <td>
                  {Math.max(ManganeseSulfate, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(ManganeseSulfate, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>9</td>
                <td>CuSO4.5H2O</td>
                <td>
                  {Math.max(CopperSulfate, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(CopperSulfate, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>10</td>
                <td>Lime[Ca(OH)2]</td>
                <td>
                  {Math.max(Lime, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(Lime, 0)}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>11</td>
                <td>CaSO4.2H2O</td>
                <td>
                  {Math.max(CalciumSulfate, 0)}{" "}
                  {orchards.includes(reportData?.request?.cropName)
                    ? "को.ग./पेड़"
                    : "  को.ग./एकोड़"}
                </td>
                <td>{Math.max(CalciumSulfate, 0)}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="footer">
          {OrganicCarbon.condition === "Low" && (
            <p>
              सावधान* [ध्यान दें] - वारीत रूप से अपने खेत में 10 टन/प्रति एकड़
              की दर से गोबर की खाद का प्रयोग करें।
            </p>
          )}
          <br />
          <p>
            यह रिपोर्ट कार्ड 1 वर्ष के लिए मान्य है। कृपया इसके बाद संपर्क करें:
            7309140755।
          </p>
        </div>
      </div>
    </>
  );
};

export default SoilTestCard;
