import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { SoilTestingRepository } from "../../repository/soilTesting.repository copy.js";

const useUpdateRequestsViewModel = () => {
  const soilTestingRepository = new SoilTestingRepository();
  const alert = useAlert();
  const [allRequestsList, setAllRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const getAllRequests = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await soilTestingRepository.getAllSoilData(page);
      setAllRequests(response.data);
      setTotalPages(response.pages);
      setCurrentPage(response.page);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };

  const getSoilData = async (id) => {
    try {
      setIsLoading(true);
      const response = await soilTestingRepository.getSoilData(id);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };

  useEffect(() => {
    getAllRequests(1);
  }, []);

  const handleView = (request) => {
    setSelectedRequest(request);
    getSoilData(request._id);
    setIsViewModalOpen(true);
  };

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedRequest(null);
  };

  return {
    allRequestsList,
    isLoading,
    totalPages,
    currentPage,
    isViewModalOpen,
    selectedRequest,
    getAllRequests,
    handleView,
    handleCloseViewModal,
    setSelectedRequest,
    getSoilData,
  };
};

export default useUpdateRequestsViewModel;
