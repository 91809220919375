import "./ndvitable.css";
import React from "react";
import { useAlert } from "react-alert";
import useNdviDashboard from "./useNdviDashboard";

const NdviDashboardScreen = () => {
  const alert = useAlert();
  const {
    ndviData,
    isLoading,
    editableRowId,
    editedRowData,
    handleEditRow,
    handleSubmitRow,
    handleChange,
  } = useNdviDashboard();
  if (isLoading) return <div>Loading...</div>;
  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Farmer Name</th>
            <th>Mobile No</th>
            <th>Village</th>
            <th>District</th>
            <th>State</th>
            <th>Plot No</th>
            <th>Crop Name</th>
            <th>Variety</th>
            <th>Crop Duration</th>
            <th>Crop Stage</th>
            <th>Sowing Season</th>
            <th>Lat,Long</th>
            <th>NDVI Max</th>
            <th>Last Updated</th>
            <th>Satellite</th>
            <th>Monitoring Status</th>
            <th>Operation</th>
          </tr>
        </thead>
        <tbody>
          {ndviData.map((data, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{data.farmer_name}</td>
              <td>{data.farmer_phone_number}</td>
              <td>{data.farmer_village}</td>
              <td>{data.farmer_district_en}</td>
              <td>{data.farmer_state}</td>
              <td>{data.plot_no}</td>
              <td>{data.crop_name}</td>
              <td>{data.crop_variety}</td>
              <td>{data.crop_duration}</td>
              <td>{data.current_crop_stage}</td>
              <td>{data.sowing_season}</td>
              <td>
                {data.coordinates.latitude},{data.coordinates.longitude}
              </td>
              <td>
                {editableRowId === index ? (
                  <input
                    type="number"
                    value={editedRowData.ndvi || ""}
                    onChange={(e) =>
                      handleChange(index, "ndvi", e.target.value)
                    }
                  />
                ) : (
                  data.ndvi_current
                )}
              </td>
              <td>
                {editableRowId === index ? (
                  <input
                    type="date"
                    value={editedRowData.lastUpdated || ""}
                    onChange={(e) =>
                      handleChange(index, "lastUpdated", e.target.value)
                    }
                  />
                ) : data?.last_updated ? (
                  new Date(data?.last_updated).toLocaleDateString()
                ) : (
                  "Not Available"
                )}
              </td>
              {/* 2025-03-15T00:00:00.000Z format this data */}
              {/* <td>{data.last_updated}</td> */}
              <td>
                {editableRowId === index ? (
                  <select
                    value={editedRowData.satellite || ""}
                    onChange={(e) =>
                      handleChange(index, "satellite", e.target.value)
                    }
                    disabled={data?.satellite}
                  >
                    <option value="AWiFS and Sentinel">
                      AWiFS and Sentinel
                    </option>
                    <option value="Modis">Modis</option>
                  </select>
                ) : data?.satellite ? (
                  data?.satellite
                ) : (
                  "Not Available"
                )}
              </td>

              <td>{data.monitoring_status}</td>
              <td>
                {editableRowId === index ? (
                  <button
                    className="submit-btn"
                    onClick={() => handleSubmitRow(index)}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="edit-btn"
                    onClick={() => handleEditRow(index)}
                  >
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NdviDashboardScreen;
