import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";

export class NdviRepository {
  static appUrl = new AppUrl();
  static apiService = new ApiService();
  static async getNdviData(pageNo) {
    try {
      const url = `${this.appUrl.ndviEndPoint}/${pageNo}`;
      return this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  static async updateNdviData(id, obj) {
    try {
      const url = `${this.appUrl.ndviEndPoint}/${id}`;
      return this.apiService.getPutApiResponse(url, obj);
    } catch (error) {
      throw error;
    }
  }
}
