import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { SoilTestingRepository } from "../../repository/soilTesting.repository copy.js";

import { AuthRepository } from "../../repository/auth.repository";

const useSoilTestingViewModel = () => {
  const soilTestingRepository = new SoilTestingRepository();
  const authRepository = new AuthRepository();
  const alert = useAlert();
  const [allRequestsList, setAllRequests] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTable, setSearchTable] = useState([]);
  const [commentsArray, setCommentsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [centers, setCenters] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedFieldUnit, setSelectedFieldUnit] = useState("Acer");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("None");
  const [formData, setFormData] = useState({
    name: "",
    block: "",
    fieldKharaNumber: "",
    village: "",
    district: "",
    fieldCoordinates: "",
    phoneNumber: "",
    testId: "",
    fieldSize: "",
    irrigationMode: "",
    soilType: "",
    cropName: "",
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await createNewRequests({
      ...formData,
      fieldSize: `${formData.fieldSize} ${selectedFieldUnit}`,
    });
    toggleModel(); // Close the modal
    getAllRequests();
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      getAllRequests(page);
    }
  };
  const toggleModel = () => {
    try {
      setIsOpen(!isOpen);
      if (isOpen) {
        setCommentsArray([]);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };
  const getAllRequests = async (page) => {
    try {
      setIsLoading(true);
      const response = await soilTestingRepository.getAllRequests(page);
      setAllRequests(response.data);
      setTotalPages(response.pages);
      setCurrentPage(response.page);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  const createNewRequests = async (body) => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      const centreId = userData?.centre || "667aed180ff53f486a2c1260";
      // Append the centre id to the body
      const updatedBody = {
        ...body,
        centre: centreId,
      };

      setIsLoading(true);
      const response = await soilTestingRepository.createNewRequest(
        updatedBody
      );
      console.log(response);
      alert.success("Request Created Successfully!");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  const search = (event) => {
    try {
      const searchText = event.target.value;
      const searchData = history.filter((vehicle) => {
        if (
          vehicle.vehicleId.includes(searchText) ||
          vehicle.user.companyId.includes(searchText) ||
          vehicle.user.name.includes(searchText)
        )
          return vehicle;
      });
      setSearchTable([...searchData]);
    } catch (err) {
      alert.error(err.message);
    }
  };
  const handleUpdate = async (request) => {
    setSelectedRequest(request);
    setIsUpdateModalOpen(true);
    try {
      const data = await soilTestingRepository.getSavedData(request._id);
      if (data) {
        setSelectedRequest((prev) => {
          return {
            ...prev,
            ...data,
            _id: prev._id,
          };
        });
      }
    } catch (err) {
      alert.error(err.message);
    }
  };
  const handleCloseModal = () => {
    setIsUpdateModalOpen(false);
    setSelectedRequest(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mandatoryFields = [
      "OrganicCarbon",
      "Conductivity",
      "pH",
      "Nitrogen",
      "Phosphorus",
      "Potassium",
    ];
    // Collect form data
    const formData = {
      request: selectedRequest?._id,
      Nitrogen: selectedRequest.Nitrogen,
      Phosphorus: selectedRequest.Phosphorus,
      Potassium: selectedRequest.Potassium,
      Sulfur: selectedRequest.Sulfur,
      Copper: selectedRequest.Copper,
      Zinc: selectedRequest.Zinc,
      Manganese: selectedRequest.Manganese,
      Iron: selectedRequest.Iron,
      Boron: selectedRequest.Boron,
      OrganicCarbon: selectedRequest.OrganicCarbon,
      Conductivity: selectedRequest.Conductivity,
      pH: selectedRequest.pH,
      Urea: selectedRequest.Urea,
      DAP: selectedRequest.DAP,
      MOP: selectedRequest.MOP,
      ZincSulfate: selectedRequest.ZincSulfate,
      Gypsum: selectedRequest.Gypsum,
      Borax: selectedRequest.Borax,
      FerrousSulfate: selectedRequest.FerrousSulfate,
      ManganeseSulfate: selectedRequest.ManganeseSulfate,
      CopperSulfate: selectedRequest.CopperSulfate,
      Lime: selectedRequest.Lime,
      CalciumSulfate: selectedRequest.CalciumSulfate,
    };

    // Filter out undefined values
    const filteredFormData = {};
    for (const key in formData) {
      if (formData[key] !== undefined) {
        filteredFormData[key] = formData[key];
      }
    }

    // Check mandatory fields for undefined, null or empty values
    for (const key of mandatoryFields) {
      if (
        filteredFormData[key] === undefined ||
        filteredFormData[key] === null ||
        filteredFormData[key] === ""
      ) {
        alert.error(`Please fill in the ${key} field.`);
        return;
      }
    }
    for (const key in filteredFormData) {
      const value = filteredFormData[key];
      if (parseFloat(value) < 0) {
        alert.error(`${key} cannot be negative.`);
        return;
      }
    }

    // Submit the form data
    await createSoilData(filteredFormData);
    getAllRequests();
    handleCloseModal();
  };
  const handleSave = async () => {
    try {
      const mandatoryFields = [
        "OrganicCarbon",
        "Conductivity",
        "pH",
        "Nitrogen",
        "Phosphorus",
        "Potassium",
      ];

      // Collect form data
      console.log(selectedRequest, " dasdrtaasljkfsda");
      const formData = {
        request: selectedRequest._id,
        Nitrogen: selectedRequest.Nitrogen,
        Phosphorus: selectedRequest.Phosphorus,
        Potassium: selectedRequest.Potassium,
        Sulfur: selectedRequest.Sulfur,
        Copper: selectedRequest.Copper,
        Zinc: selectedRequest.Zinc,
        Manganese: selectedRequest.Manganese,
        Iron: selectedRequest.Iron,
        Boron: selectedRequest.Boron,
        OrganicCarbon: selectedRequest.OrganicCarbon,
        Conductivity: selectedRequest.Conductivity,
        pH: selectedRequest.pH,
        Urea: selectedRequest.Urea,
        DAP: selectedRequest.DAP,
        MOP: selectedRequest.MOP,
        ZincSulfate: selectedRequest.ZincSulfate,
        Gypsum: selectedRequest.Gypsum,
        Borax: selectedRequest.Borax,
        FerrousSulfate: selectedRequest.FerrousSulfate,
        ManganeseSulfate: selectedRequest.ManganeseSulfate,
        CopperSulfate: selectedRequest.CopperSulfate,
        Lime: selectedRequest.Lime,
        CalciumSulfate: selectedRequest.CalciumSulfate,
      };

      console.log(formData);
      // Filter out undefined values
      const filteredFormData = {};
      for (const key in formData) {
        if (formData[key] !== undefined) {
          filteredFormData[key] = formData[key];
        }
      }

      // Check mandatory fields for undefined, null or empty values
      for (const key of mandatoryFields) {
        if (
          filteredFormData[key] === undefined ||
          filteredFormData[key] === null ||
          filteredFormData[key] === ""
        ) {
          alert.error(`Please fill in the ${key} field.`);
          return;
        }
      }
      console.log(filteredFormData);
      for (const key in filteredFormData) {
        const value = filteredFormData[key];
        if (parseFloat(value) < 0) {
          alert.error(`${key} cannot be negative.`);
          return;
        }
      }

      // Save  the form data
      await saveSoilData(filteredFormData);
      getAllRequests();
      handleCloseModal();
    } catch (error) {
      alert.error(error.message);
    }
  };
  const createSoilData = async (body) => {
    try {
      setIsLoading(true);
      const response = await soilTestingRepository.createSoilData(body);
      alert.success("Data Added Successfully!");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  const handleFieldUnit = (unit) => {
    setSelectedFieldUnit(unit);
  };

  const saveSoilData = async (body) => {
    try {
      setIsLoading(true);
      const response = await soilTestingRepository.saveSoilData(body);
      alert.success("Data Updated Successfully!");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  // useEffect(() => {
  //   const getCenters = async () => {
  //     try {
  //       const response = await authRepository.getCentres();
  //       const centers = response.map((data) => data?.name);
  //       setCenters([]);
  //     } catch (error) {
  //       alert.error(error.message);
  //     }
  //   };
  //   getCenters();
  // }, []);

  useEffect(() => {
    getAllRequests(1);
  }, []);
  return {
    toggleModel,
    centers,
    isOpen,
    isLoading,
    allRequestsList,
    search,
    searchTable,
    commentsArray,
    getAllRequests,
    createNewRequests,
    isUpdateModalOpen,
    handleUpdate,
    handleCloseModal,
    handleSubmit,
    selectedRequest,
    setSelectedRequest,
    totalPages, // Return total pages
    currentPage,
    handleSave,
    formData,
    sortOrder,
    searchTerm,
    handleSearchChange,
    handleFormChange,
    selectedFieldUnit,
    handleFieldUnit,
    handleFormSubmit,
    handlePageChange,
  };
};
export default useSoilTestingViewModel;
