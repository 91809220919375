import "./category.css";
import Component from "../../../components";
import ScreenComponent from "./components";
import useJankariViewModel from "./categoryViewModel";
const JankariCategory = () => {
  const {
    toggleModel,
    heading,
    isOpen,
    isLoading,
    categoriesList,
    newCategoryData,
    getNewCategoryData,
    toggleHeading,
    createCategory,
    deleteCategory,
    toggleDeleteRequest,
    parentCategoryList,
    deleteAlert,
    editCategory,
    openEditModel,
    pageNo,
    previousPage,
    nextPage,
    categoryInfo,
    toggleCategoryInfoModel,

    isTagModalOpen,
    toggleTagModal,
    tags,
    newTag,
    handleTagChange,
    addNewTag,
    removeTag,
  } = useJankariViewModel();
  return (
    <div className="container">
      <Component.DeleteAlert
        cancelFunction={toggleDeleteRequest()}
        dleteFunction={deleteCategory}
        open={deleteAlert}
      >
        Do you want to delete this Category
      </Component.DeleteAlert>
      <Component.Row
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1> {heading} </h1>
        <Component.Button title="Add Category" onClick={toggleModel}>
          Open Model
        </Component.Button>
      </Component.Row>
      <Component.Spacer position="top" size={0} />
      <ScreenComponent.CategoryModel
        isOpen={isOpen}
        toggleModel={toggleModel}
        heading={heading}
        toggleHeading={toggleHeading}
        newCategoryData={newCategoryData}
        getNewCategoryData={getNewCategoryData}
        createCategory={createCategory}
        isLoading={isLoading}
        parentCategoryList={parentCategoryList}
        editCategory={editCategory}
        //this is to add tags
        tags={tags}
        newTag={newTag}
        addNewTag={addNewTag}
        isTagModalOpen={isTagModalOpen}
        toggleTagModal={toggleTagModal}
        handleTagChange={handleTagChange}
        removeTag={removeTag}
      />
      <Component.Row>
        <div>
          <input
            type="radio"
            checked={heading === "Category" ? true : false}
            value="Category"
            name="category"
            onClick={toggleHeading}
            id="parentCategory"
          />
          <label htmlFor="parentCategory"> Parent Category</label>
        </div>
        <Component.Spacer position="right" size={20} />
        <div>
          <input
            type="radio"
            value="Sub Category"
            name="category"
            onClick={toggleHeading}
            id="subCategory"
          />
          <label htmlFor="subCategory"> Sub Category</label>
        </div>
      </Component.Row>
      <Component.ChildContainer
        className="cardContainer"
        justifyContent="center"
        flexWrap="wrap"
      >
        {isLoading ? (
          <Component.LoadingAnimation color="green" />
        ) : (
          <>
            {categoriesList.length <= 0 ? (
              <h1> Nothing To Show </h1>
            ) : (
              <>
                {categoriesList.map((Category) => {
                  let background = Category.backgroundImage.split("/")[3];
                  background = `https://d336izsd4bfvcs.cloudfront.net/${background}`;
                  let icon = Category.icon.split("/")[3];
                  icon = `https://d336izsd4bfvcs.cloudfront.net/${icon}`;
                  return (
                    <Component.GridCard
                      profile={Category.icon}
                      name={Category.hindiName}
                      key={Category._id}
                      imgurl={Category.backgroundImage}
                      description={Category.hindiDescription}
                      getInfo={toggleCategoryInfoModel(Category._id)}
                      dropDownOptions={[
                        {
                          title: "Edit",
                          method: openEditModel(Category._id),
                        },
                        {
                          title: "Delete",
                          method: toggleDeleteRequest(Category._id),
                        },
                      ]}
                    />
                  );
                })}
              </>
            )}
          </>
        )}
        {categoryInfo && (
          <Component.InfoModel
            title={categoryInfo.hindiName}
            isOpen={categoryInfo ? true : false}
            profile={categoryInfo.icon}
            image={categoryInfo.backgroundImage}
            description={categoryInfo.hindiDescription}
            toggleModel={toggleCategoryInfoModel(null)}
          />
        )}
      </Component.ChildContainer>
      <div className="pagination">
        <Component.ChildContainer justifyContent="center">
          <Component.PageNavigator
            previousPage={previousPage}
            nextPage={nextPage}
          >
            {pageNo}
          </Component.PageNavigator>
        </Component.ChildContainer>
      </div>
    </div>
  );
};
export default JankariCategory;
