import { useContext } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Outlet } from "react-router-dom";
import { appColors } from "../../res/colors";
import Component from "../../components";
import "./index.css";
import { NavIcons } from "../../res/assets";
import NavItem from "./components";
import { AuthContext } from "../../context/auth.context";
const menuItemStyles = {
  padding: 10,
  "&:hover": {
    backgroundColor: "#f8f8f8we",
    color: "black",
  },
};
const mainStyles = {
  backgroundColor: appColors.lightColor,
  overflow: "hidden",
};
const deviceWidth = window.innerWidth;

export default function SideBar() {
  const { toggleSidebar, broken } = useProSidebar();
  const { setToken } = useContext(AuthContext);
  // const user = JSON.parse(localStorage.getItem("user"));
  const user = {
    roles: "admin",
  };

  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
  };
  const role = JSON.parse(localStorage.getItem("user")).roles;
  return (
    <div className="sidebarContainer">
      <Sidebar breakPoint="lg" backgroundColor="#fff">
        <div className="headerContainer">
          <div>
            <div className="logoSidebar">
              <NavIcons.LogoBig />
            </div>
            <Menu>
              {role === "Admin" && (
                <>
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/stats"
                        ActiveIcon={NavIcons.DasFill}
                        InActiveIcon={NavIcons.DasEmpty}
                        title="Dashboard"
                      />
                    }
                  >
                    Stats
                  </MenuItem>
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/home"
                        ActiveIcon={NavIcons.DasFill}
                        InActiveIcon={NavIcons.DasEmpty}
                        title="Feeds"
                      />
                    }
                  >
                    Feed
                  </MenuItem>
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/feedCategory"
                        ActiveIcon={NavIcons.TaskHistoryEmpty}
                        InActiveIcon={NavIcons.TaskHistoryFill}
                        title="Feed Category"
                      />
                    }
                  >
                    Your Feeds
                  </MenuItem>
                  {["Manager", "Supervisor"].includes(user.roles) && (
                    <MenuItem
                      rootStyles={menuItemStyles}
                      component={
                        <NavItem
                          to="/workManagement/assigntask"
                          ActiveIcon={NavIcons.WorkFill}
                          InActiveIcon={NavIcons.WorkEmpty}
                          title="Work Management"
                        />
                      }
                    />
                  )}
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/jankari/category"
                        ActiveIcon={NavIcons.WorkerFill}
                        InActiveIcon={NavIcons.WorkerEmpty}
                        title="Jankari Category"
                      />
                    }
                  >
                    Jankari Category
                  </MenuItem>
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/jankari/post"
                        ActiveIcon={NavIcons.WorkerFill}
                        InActiveIcon={NavIcons.WorkerEmpty}
                        title="Jankari Post"
                      />
                    }
                  >
                    Jankari Post
                  </MenuItem>

                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/chat"
                        ActiveIcon={NavIcons.TaskHistoryEmpty}
                        InActiveIcon={NavIcons.TaskHistoryFill}
                        title="Chat"
                      />
                    }
                  >
                    Chat
                  </MenuItem>
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/users"
                        ActiveIcon={NavIcons.TaskHistoryEmpty}
                        InActiveIcon={NavIcons.TaskHistoryFill}
                        title="Users"
                      />
                    }
                  >
                    Chat
                  </MenuItem>
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/employee"
                        ActiveIcon={NavIcons.TaskHistoryEmpty}
                        InActiveIcon={NavIcons.TaskHistoryFill}
                        title="Employees"
                      />
                    }
                  >
                    Chat
                  </MenuItem>
                  <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/crops"
                        ActiveIcon={NavIcons.TaskHistoryEmpty}
                        InActiveIcon={NavIcons.TaskHistoryFill}
                        title="Crops"
                      />
                    }
                  />
                  {/* <MenuItem
                    rootStyles={menuItemStyles}
                    component={
                      <NavItem
                        to="/soiltesting"
                        ActiveIcon={NavIcons.TaskHistoryEmpty}
                        InActiveIcon={NavIcons.TaskHistoryFill}
                        title="Soil Testing Requests"
                      />
                    }
                  /> */}
                </>
              )}
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/agriStick"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="AgriStick"
                  />
                }
              >
                AgriStick
              </MenuItem>
              {/* Soiltest Start */}
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/soiltesting"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="Soil Testing Requests"
                  />
                }
              >
                Soiltesting
              </MenuItem>
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/updateRequests"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="Update Soiltest Request"
                  />
                }
              >
                Update Soiltest Request
              </MenuItem>
              {/* Soiltest End */}
              {/* Ndvi Start  ndviDashboard*/}
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/ndviDashboard"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="Ndvi Dashboard"
                  />
                }
              >
                Ndvi Dashboard
              </MenuItem>
              {/* Ndiv End */}
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/report"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="Reports"
                  />
                }
              >
                Reports
              </MenuItem>
              <MenuItem
                rootStyles={menuItemStyles}
                component={
                  <NavItem
                    to="/intrest"
                    ActiveIcon={NavIcons.TaskHistoryEmpty}
                    InActiveIcon={NavIcons.TaskHistoryFill}
                    title="User Intrest"
                  />
                }
              >
                User Intrest
              </MenuItem>
            </Menu>
          </div>
          <div className="logOutButtonContainer">
            <Component.Button onClick={handleLogout} title="Logout">
              Logout
            </Component.Button>
          </div>
        </div>
      </Sidebar>
      <main
        style={{
          width: broken ? deviceWidth : deviceWidth - 250,
          ...mainStyles,
        }}
      >
        {broken && (
          <div className="hamburgerIcon">
            <button className="sb-button" onClick={() => toggleSidebar()}>
              <NavIcons.HamburgerIcon />
            </button>
            <div className="topLogo">
              <NavIcons.LogoBig />
            </div>
          </div>
        )}
        <Outlet context={broken} />
      </main>
    </div>
  );
}
